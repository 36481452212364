<template>
  <div class="pb-20 pt-20">
        <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">

            <div v-if="campaigns.length > 0">
                <div class="mt-5 grid grid-cols-4 gap-4" v-for="campaign in campaigns" :key="campaign.id">
                    <div class="col-span-4">
                        <CampaignLargeItem 
                            :image="campaign.image" 
                            :title="campaign.title" 
                            :slug="campaign.slug" 
                            :sumDonation="campaign.sum_donation"
                            :targetDonation="campaign.target_donation"
                            :maxDate="campaign.max_date" /> 
                    </div>
                </div>
            </div>
            <div v-else>

                <div v-for="index in 2" :key="index" class="grid grid-cols-1 bg-white rounded shadow-md p-3 text-sm mt-4 mb-4">
                    <FacebookLoader class="h-24"/>
                </div>

            </div>

        </div>

        <div class="text-center mt-4 mb-4" v-show="nextExists">
            <a @click="loadMore"
                class="bg-gray-700 text-white p-2 px-3 rounded-md shadow-md focus:outline-none focus:bg-gray-900 cursor-pointer">LIHAT
                SEMUA <i class="fa fa-long-arrow-alt-right"></i></a>
        </div>

  </div>
</template>

<script>

    //hook vue
    import { computed, onMounted, watch } from 'vue'

    //vuex
    import { useStore } from 'vuex'

    //hook vue router
    import { useRoute } from "vue-router";

    //vue content loader
    import { FacebookLoader } from 'vue-content-loader'

    import CampaignLargeItem from '@/components/CampaignLargeItem.vue'

    export default {

        components: {
            FacebookLoader,
            CampaignLargeItem  // <-- register component FacebooLoader dari Vue Content Loader
        },

        setup() {
            
            //vue route
            const route = useRoute();

            //store vuex
            const store = useStore()
            const setDocumentTitle = (id) => {
                const title = id === '1' ? 'Top Program' : 'Program Pilihan';
                document.title = `Lihat ${title}`;
            };
            watch(() => route.params.id, (newId) => {
                setDocumentTitle(newId); // Update title when route id changes
            }, { immediate: true });
            //onMounted akan menjalankan action "getCampaign" di module "campaign"
            onMounted(() => {
                // const judul = route.params.id === '1' ? 'Top Program' : 'Program Pilihan';
                // console.log(judul, route.params.id);
                setDocumentTitle(route.params.id)
                store.state.campaign.campaignPrograms = []
                
                store.dispatch('campaign/getCampaignProgram', route.params.id)
            })
            // watch(() => route.params.id, (newId) => {
            //     const judul = newId === '1' ? 'Top Program' : 'Program Pilihan';
            //     console.log(judul);
                
            //     document.title = `Lihat ${judul}`;
            // }, { immediate: true });

            //digunakan untuk get data  state "campaigns" di module "campaign" 
            const campaigns = computed(() => {
                return store.state.campaign.campaignPrograms
            })

            //get status NextExists
            const nextExists = computed(() => {
                return store.state.campaign.nextExistsPrograms
            })

            //get nextPage
            const nextPage = computed(() => {
                return store.state.campaign.nextPagePrograms
            })

            //loadMore function
            function loadMore() {
                store.dispatch('campaign/getLoadMore', nextPage.value)
            }    

            return {
                campaigns,      // <-- return campaigns
                nextExists,     // <-- return nextExists,
                nextPage,       // <-- return nextPage
                loadMore,        // <-- return loadMore
            }

        }

    }
</script>

<style>

</style>